import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import StatusBarBloc from "src/hybrid/components/StatusBarBloc";
import { useBloc } from "src/state/state";

const TitlePlaceholder = styled.h1`
  body & {
    opacity: 0;
    pointer-events: none;
    text-align: center;
    font-weight: 500;
    padding: 0;
    margin: 0;
    height: var(--safe-area-top, 0px);
  }
`;

const AppPageTitle: FC<{ title?: string }> = (props) => {
  const [{ title }, { setTitle }] = useBloc(StatusBarBloc);

  useEffect(() => {
    setTitle(props.title);
    return () => {
      setTitle("");
    };
  }, [props.title]);

  return (
    <TitlePlaceholder
      style={{
        height: "var(--status-bar-top, auto)"
      }}
    >
      {title}
    </TitlePlaceholder>
  );
};

export default AppPageTitle;
